<template>
  <div id="app">
    <router-view v-wechat-title="$route.meta.title"/>
  </div>
</template>

<script>
	
export default {
  name: 'App',
  beforeCreate() {
    this.$store.dispatch('app/appInit');
  }
}
</script>

<style>

html, body {
    height: 100%;
}    

#app {
    height: 100%;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

</style>
