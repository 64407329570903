import request from '@/api/request.js'

/**
 *
 */
// 获取网页授权openid【员工】
export function getUserOpenidApi(data) {
  return request({
    url: `/fission/fission_user/getOpenid`,
    method: 'get',
    params: data
  })
}

// 获取网页授权用户信息【员工】
export function getUserWxInfoApi(data) {
  return request({
    url: `/fission/fission_user/getUserinfo`,
    method: 'get',
    params: data
  })
}

// 获取网页授权openid【访客/学员】
export function getVisitorOpenidApi(data) {
  return request({
    url: `/fission/fission_visitor/getOpenid`,
    method: 'get',
    params: data
  })
}

// 获取网页授权用户信息【访客/学员】
export function getVisitorWxInfoApi(data) {
  return request({
    url: `/fission/fission_visitor/getUserinfo`,
    method: 'get',
    params: data
  })
}

// 获取网页授权详情【访客】
export function getVisitoAuthInfoApi(data) {
  return request({
    url: `/fission/fission_visitor/getAuthInfo`,
    method: 'get',
    params: data,
    headers:{
        'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 获取网页授权详情【员工】
export function getUserAuthInfoApi(data) {
  return request({
    url: `/fission/fission_user/getAuthInfo`,
    method: 'get',
    params: data,
    headers:{
        'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 *
 */
// 成为活动员工
export function beUserApi(data) {
  return request({
    url: `/fission/fission_user/beUser`,
    method: 'post',
    data: data,
    headers:{
        'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 活动详情
export function activityInfoApi(data) {
  let id = data.id;
  data.id = undefined;
  return request({
    url: `/fission/fission_activity/info/${id}`,
    method: 'get',
    params: data,
    headers:{
        'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 活动内容
export function activityContentApi(data) {
  let id = data.id;
  data.id = undefined;
  return request({
    url: `/fission/fission_activity/content/${id}`,
    method: 'get',
    params: data,
    headers:{
        'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 支付定金
export function payDepositApi(data) {
  return request({
    url: `/fission/fission_order/payDeposit`,
    method: 'post',
    data: data,
  })
}

// 支付尾款
export function payBalanceApi(data) {
  return request({
    url: `/fission/fission_order/payBalance`,
    method: 'post',
    data: data,
  })
}

// 获取员工详情
export function getUserInfoApi(data) {
  return request({
    url: `/fission/fission_user/infoByOpenid`,
    method: 'get',
    params: data,
    headers:{
        'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 获取访客详情
export function getVisitorInfoApi(data) {
  return request({
    url: `/fission/fission_visitor/infoByOpenid`,
    method: 'get',
    params: data,
    headers:{
        'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 获取学员详情
export function getMemberInfoApi(data) {
  return request({
    url: `/fission/fission_member/infoByOpenid`,
    method: 'get',
    params: data,
    headers:{
        'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 订单列表
export function activityOrdeListApi(data) {
  return request({
    url: `/fission/fission_order/queryPage`,
    method: "post",
    server: "java",
    data,
  });
}

// 获取活动mock数据
export function activityMockDataApi(data) {
  return request({
    url: `/fission/fission_moremember/find`,
    method: "post",
    server: "java",
    data,
    headers:{
        'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

// 获取系统当前时间
export function activityNowTimeApi(data) {
  return request({
    url: `/fission/fission_activity/now`,
    method: "get",
    server: "java",
    data,
  });
}


