import Cookies from 'js-cookie'

/**
 * 返回小时数
 *
 */
export function getExpiresHour(num = 72) {
  let time= new Date(new Date().getTime() + num * 60 * 60 * 1000);
  return {
    expires: time
  }
}


export function getItemsPro(param) {
  return localStorage.getItem(param)
}

export function setItemsPro(param, data) {
  return localStorage.setItem(param, data);
}

export function removeItemsPro(param) {
  return localStorage.removeItem(param)
}


/**
 * public
 *
 */
export function getItems(param) {
  return Cookies.get(param)
}

export function setItems(param, data, time) {
    
    if(time) {
       return Cookies.set(param, data, getExpiresHour(time));
    }
    else {
        return Cookies.set(param, data);
    }

}

export function removeItems(param) {
  return Cookies.remove(param)
}

