import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: "/",
		redirect: "/appActivity/index"
	},  
	{
		path: '/appActivity/index',  // 首页测试
		name: 'appIndex',
		meta: {
			title: "首页测试",
		},
		component: () => import('@/views/app/index.vue')
	},
    {
        path: '/appActivity/activity/:activityNo',  // 活动落地页
        name: 'activityActivity',
        meta: {
            title: "活动页面",
        },
        component: () => import('@/views/activity/activity/activity.vue')
    },
    {
        path: '/appActivity/activity/:activityNo/:redirect',  // 活动落地页
        name: 'activityActivityRedirect',
        meta: {
            title: "活动页面",
        },
        component: () => import('@/views/activity/activity/activity.vue')
    },
    {
        path: '/appActivity/beUser/:activityNo',  // 成为员工
        name: 'activityBeUser',
        meta: {
            title: "成为活动员工",
        },
        component: () => import('@/views/activity/beUser/beUser.vue')
    },
    {
        path: '/appActivity/beUser/:activityNo/:redirect',  // 成为员工
        name: 'activityBeUserRedirect',
        meta: {
            title: "成为活动员工",
        },
        component: () => import('@/views/activity/beUser/beUser.vue')
    },
    {
        path: '/appActivity/userOrder/:activityNo',  // 员工订单
        name: 'activityUserOrder',
        meta: {
            title: "员工订单",
        },
        component: () => import('@/views/activity/userOrder/userOrder.vue')
    },
    {
        path: '/appActivity/userOrder/:activityNo/:redirect',  // 员工订单
        name: 'activityUserOrder',
        meta: {
            title: "员工订单",
        },
        component: () => import('@/views/activity/userOrder/userOrder.vue')
    },
    {
      // 会匹配所有路径
      path: '*',
      name: 'page404',
      meta: {
        title: "页面不存在",
      },
      component: () => import('@/views/app/404.vue')
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes
})

export default router
