import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vconsole from 'vconsole'
import 'reset-css'
import '@/style/index.css'

import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)

//vant ui 引入
import { Toast } from 'vant';
import 'vant/lib/toast/style';
Vue.use(Toast);

Vue.config.productionTip = false;

//剪切板
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

//开启code调试
if(process.env.VUE_APP_OPEN_DEBUG==true) {
    let vConsole = new Vconsole();
    Vue.use(vConsole);
}


new Vue({
  render: h => h(App),
  router, store,
}).$mount('#app')
