
import router from '@/router'

const state = {
  //app相关
  appInitEnd: false,        //app是否初始化完成
  initAwaitList: [],        //初始化等待列表，app初始化完成后将全部调用
}

const mutations = {
  appInitEnd_set: (state, appInitEnd) => {
      state.appInitEnd = Boolean(appInitEnd);
  },
  initAwaitList_set: (state, initAwaitList) =>{
      state.initAwaitList = initAwaitList;
  },
  initAwaitList_add: (state, item) =>{
      state.initAwaitList.push(item);
  },
}

const actions = {
  
  //app初始化
  async appInit({ commit, dispatch, state }) {
    
    //这里执行全局初始化的内容
    
    commit('appInitEnd_set', true);
    
    //循环调用回调
    state.initAwaitList.forEach(item=>{
        item();
    })
    commit('initAwaitList_set', []);
  },
  
  //等待app初始化完成
  appInitSync({commit, dispatch, state}) {
      return new Promise((resolve, reject) => { 
          if(state.appInitEnd) {
              //已经初始化完成则立即执行
              resolve();
          }
          else {
              //存放在等待列表中
              commit('initAwaitList_add', resolve);
          }
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
