
import router from '@/router'
import { Dialog } from 'vant';
import 'vant/es/dialog/style';
import { getItems, setItems } from '@/utils/auth'
import { getUserOpenidApi, getUserWxInfoApi, getVisitorOpenidApi, getVisitorWxInfoApi, getUserInfoApi,getVisitorInfoApi, getMemberInfoApi, getVisitoAuthInfoApi, getUserAuthInfoApi } from '@/api/activity.js';

const state = {  
  //微信公众号相关
  openid: '',
  wxAccessToken: '',
  wxInitEnd: false,         //微信是否初始化完毕
  
  //网页授权信息，含用户本次活动下的状态
  authInfo: {},
  
  //当前用户信息
  userInfo: {},
  
  //学员信息【如果是学员】
  memberInfo: {},
  
}

const mutations = {
  openid_set: (state, openid) => {
      state.openid = openid;
  },
  authInfo_set: (state, authInfo) => {
      state.authInfo = authInfo;
  },
  userInfo_set: (state, userInfo) => {
      state.userInfo = userInfo;
  },
  memberInfo_set: (state, memberInfo) => {
      state.memberInfo = memberInfo;
  },
  wxAccessToken_set: (state, wxAccessToken) => {
      state.wxAccessToken = wxAccessToken;
  },
  wxInitEnd_set: (state, wxInitEnd) => {
      state.wxInitEnd = Boolean(wxInitEnd);
  },
}

const actions = {

  //微信相关信息初始化
  /**
   * param.activityId 为活动id
   * param.isUser  表示是否为员工。因为员工跟访客调的接口不一样，所以要区分
   */
  async activityWxInit({ commit, dispatch, state }, param) {
    let _param = param || {};
    await dispatch('getOpenId', _param);
    
    //获取网页授权详情
    await dispatch('getAuthInfo', _param);

    //员工页
    if(param.isUser) {
        _param.type=2;
        if(state.authInfo.isAuth!='1') {
            await dispatch('getWxUserInfo', _param);
        }
        else {
            router.replace(window.location.pathname.replace(/^\/dance-test/g, "") + '/replace');
        }
    }
    
    //访客，落地页
    /**
     * type[1:访客;2:员工]。如果type是1，有以下两个字段：isAuth[0:未授权;1:已授权]；status[1:未有支付;3:定金已支付,尾款待支付;4:尾款已支付]
     */
    else {

        if(state.authInfo.type!=1) {
            //这里是员工
            _param.type=2;
            router.replace(window.location.pathname.replace(/^\/dance-test/g, "") +'/replace');
        }
        else {
            _param.type=1;
            if(state.authInfo.isAuth!='1') {
                await dispatch('getWxUserInfo', _param);
                
            }
            else {
                router.replace(window.location.pathname.replace(/^\/dance-test/g, "")+'/replace');
            }
        }
        
        //获取当前登录用户信息
        await dispatch('getUserInfo', _param);
    }
    
    commit('wxInitEnd_set', true);
  },
  
  //获取openId
  getOpenId({ commit, state }, param) {
    return new Promise((resolve, reject) => { 

        //活动id
        let activityId = param.activityId || '';
        
        //获取路由参数
        let geturl = window.location.href;
        let getUrlParam = geturl.split('?')[1]; 
        let getqys = new URLSearchParams('?'+getUrlParam)  //将参数放在URLSearchParams函数中
        let query = {
            code: getqys.get('code'),
            state: getqys.get('state')
        };

        //有code的话，表示是微信的回调
        if(query.code) {
            let stateParam = JSON.parse(query.state||'[]');

            //调接口，换取open,保存到本地
            let Api = param.isUser ? getUserOpenidApi : getVisitorOpenidApi;
            Api({
                activityId: activityId,
                code: query.code
            })
            .then(res=>{
                //openId
                let openid = res.data.openid || '';
                commit('openid_set', openid);
                
                let access_token = res.data.access_token ||'';
                commit('wxAccessToken_set', access_token);
                
                resolve();
            })
            .catch(res=>{
                
                router.replace(window.location.pathname.replace(/^\/dance-test/g, "")+'/replace');
                reject(res);
            })
        }
        else {
            
            //跳转, 获取code
            window.location.replace(`https://open.weixin.qq.com/connect/oauth2/authorize?`+
            `appid=${process.env.VUE_APP_WX_APPID}&`+
            `redirect_uri=${encodeURIComponent(window.location.origin+window.location.pathname)}&`+
            `response_type=code&`+
            `scope=snsapi_base`+
            `&state=${encodeURIComponent(JSON.stringify( [1] ))}#wechat_redirect`);
        }
    })
  },
  
  //获取微信用户信息
  getWxUserInfo({ commit, state }, param) {
    return new Promise((resolve, reject) => {
        
        //活动id
        let activityId = param.activityId || '';

        //获取路由参数
        let geturl = window.location.href;
        let getUrlParam = geturl.split('?')[1]; 
        let getqys = new URLSearchParams('?'+getUrlParam)  //将参数放在URLSearchParams函数中
        let query = {
            code: getqys.get('code'),
            state: getqys.get('state')
        };

        //获取stateParam
        let stateParam = JSON.parse(query.state||'[]');
        
        //有code，并且是获取userinfo【stateParam[0]==2】
        if(query.code && stateParam[0]==2) {

            //调接口，换取用户信息
            let Api = param.isUser ? getUserWxInfoApi : getVisitorWxInfoApi;
            Api({
                activityId: activityId,
                openid: state.openid,
                accessToken: state.wxAccessToken,
            })
            .then(res=>{
                router.replace(window.location.pathname.replace(/^\/dance-test/g, "")+'/replace');       
                resolve();
            })
            .catch(res=>{
                router.replace(window.location.pathname.replace(/^\/dance-test/g, "")+'/replace');
                reject(res);
            })
        }
        else {

            //检查是否近期询问过
            let sssskewu = getItems('activityWxcodePro');
            if(sssskewu=='ok') {
                //检查OK的话，可以修复链接
                router.replace(window.location.pathname.replace(/^\/dance-test/g, "")+'/replace');
            }
            else {
                //15分钟内只提示一次
                setItems('activityWxcodePro', 'ok', 0.25);
                
                //弹窗询问
                Dialog.confirm({
                  title: '提示',
                  message:
                    '请授权获取头像和昵称',
                })
                  .then(() => {
                    //跳转, 获取code
                    window.location.href =`https://open.weixin.qq.com/connect/oauth2/authorize?`+
                    `appid=${process.env.VUE_APP_WX_APPID}&`+
                    `redirect_uri=${encodeURIComponent(window.location.origin+window.location.pathname)}&`+
                    `response_type=code&`+
                    `scope=snsapi_userinfo`+
                    `&state=${encodeURIComponent(JSON.stringify( [2] ))}#wechat_redirect`;
                    
                  })
                  .catch(() => {
                    //不管之后有没有授权成功，初始化都是已经完成了的这一下。
                    //因为在微信里，然后拒绝授权的话将不会跳转
                    router.replace(window.location.pathname.replace(/^\/dance-test/g, "")+'/replace');
                  });
            }
            
            resolve();
        }
    })
  },
  
  //获取访客网页授权详情
  getAuthInfo({ commit, state }, param) {
    return new Promise((resolve, reject) => {
        
        //活动id
        let activityId = param.activityId || '';
        
        let Api = param.isUser?getUserAuthInfoApi:getVisitoAuthInfoApi;
        Api({
            activityId: activityId,
            openid: state.openid,
        })
        .then(res=>{
            let authInfo = res.data;
            commit('authInfo_set', authInfo);
            resolve(authInfo);
        })
        .catch(res=>{
            reject(res);
        })
    })
  },
  
  //获取用户详情：type[1:访客;2:员工]
  getUserInfo({ commit, state }, param) {
    return new Promise((resolve, reject) => {
        
        //活动id
        let activityId = param.activityId || '';
        
        let Api = param.type==2?getUserInfoApi:getVisitorInfoApi;
        Api({
            activityId: activityId,
            openid: state.openid,
        })
        .then(res=>{
            let userInfo = res.data;
            commit('userInfo_set', userInfo);
            resolve();
        })
        .catch(res=>{
            reject(res);
        })
    })
  },
  
  //获取学员详情
  getMemberInfo({ commit, state }, param) {
    return new Promise((resolve, reject) => {
        
        //活动id
        let activityId = param.activityId || '';
        
        let Api = getMemberInfoApi
        Api({
            activityId: activityId,
            openid: state.openid,
        })
        .then(res=>{
            let memberInfo = res.data;
            commit('memberInfo_set', memberInfo);
            resolve(res.data);
        })
        .catch(res=>{
            reject(res);
        })
    })
  },
  
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
