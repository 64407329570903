import axios from 'axios'
import qs from 'qs';
import { Dialog } from 'vant';

// 基本配置
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, 
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 300000 ,
})

// request interceptor
service.interceptors.request.use(
  config => {
    
    //delete时，提交格式为application/x-www-form-urlencoded
    if(config.method=='delete' || config.headers['Content-Type'] == 'application/x-www-form-urlencoded') {
      config.data = qs.stringify(config.data);
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(

  response => {
    const res = response.data
    const { msg, code, data } = res

    if (res.code !== 200) {
        //登录状态失效
        if (res.code == '4001') {
            Dialog.alert({
              title: '登录超时，请重新登录',
              message: '登录超时',
            }).then(() => {
              //清除token
            });
            throw { data: data, msg: msg, status: 3 }//status=3表示登录失效
        }

      throw { msg, code, data: data, status: 5 }        //status=5表示失败
    } else {
      return { msg, code, data: data, status: 1 }       //status=1表示成功
    }
  },
  error => {
    try {
      if (error.response.status == 401) {
        Dialog.alert({
          title: '登录超时，请重新登录',
          message: '登录超时',
        }).then(() => {
          //清除token
        });
        throw { data: error, msg: '', status: 3 }   //status=3表示登录失效
      }
      else if(error.response.status == 403){
        let msg = error && error.response && error.response.data && error.response.data.message
        Dialog.alert({
          title: '没有操作权限',
          message: '没有权限',
        }).then(() => {
          
        });
        throw { data: error, msg: '', status: 7 }  //status=7表示没有权限
      }
      else {
          throw { data: error, msg: '', status: 2 }   //status=2表示发生错误
        }
    }catch(e){
        
        if(e.status>0) {
          //错误已经经过封装，所以直接抛出
          throw e;        
        }
        else {
          throw { data: error, msg: '', status: 2 }       //status=2表示发生错误
        }
    }
  }
)

export default service
